.user-details-table{
  td{
    padding: 20px;
    color: #cccccc;
  }
  td:nth-child(2n) {
    color: #333333;
  }
}
.change-pwd-btn{
  cursor: pointer;
}

$success-font-color: #5ea400;
$success-bg-color: #f0f5ea;
$success-dismiss-bg-color: #b0ca92;
div .password-notification{
  padding: 1rem;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  font-size: .8em;
  color: $success-font-color;
  background-color:$success-bg-color;
  font-weight: bold;
  border-radius: 2px;
  border-top: 2px solid;
  animation: ease-in slide-in-right 1000ms;
  i {
    font-size: 5px;
    font-weight: bold;
    border-radius: 50%;
    background-color: $success-dismiss-bg-color;
    color: white;

    width: 14px;
    height: 14px;
    padding: 4px;
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
  }
}

@keyframes slide-in-right {
  from {right: -500px;}
  to {right: 0;}
}
