$greenColor: #1DDF6B;
$borderColor:  rgba(0,0,0,.1);

.sidebar-card {
  .header-icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #586FA3;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
  }
    .item{
      border-top: 1px solid $borderColor;
      padding-top: 10px;
    }
    .cross-line{
      text-decoration: line-through;
    }
    .light-gray {
      color: $cross-line;
    }
    .green {
      color: $greenColor; 
    }
    i[class^="icon-"]{
      font-size: 20px;
      padding: 2px;
      &.small {
        font-size: 15px;
      }
    }
    h2{
      font-size: 30px;
      font-family: $SemiBoldFontFamily;
      & .total-current {
        color: $XCPrimary;
      }
      & .total-pre {
        color: $XCTextField;
      }
    }
  }

.noshadow * {
  box-shadow: 0 0 0 !important;
}
