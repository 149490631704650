// .customNav{
  //background: $XCPrimary;
  //height: 150px;
// }
@media screen and (max-width: 800px) {
  .hideOnSmall{
    display: none;
  }
}
@media screen and (min-width: 800px) {
    .hideOnLarge{
      display: none;
    }
}
.navbar{
  min-height: 72px;
}
.logo{
  // height: 40px;
  width: 154px;
  margin: 15px;
}
.user-logo{
  border-radius: 50%
}

@media screen and (min-width: 1000px){
  .title-container{
    display: flex;
    * {
      display: inline-block;
      margin: 0 5px;
    }
  }
}