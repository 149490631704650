.product-button {
    .product-icon {
      font-size: 2pc;
      padding: 17px;
      padding-left: 19px;
      color:white;
      background-color:#586FA3;
      height: 70px;
      width: 70px;
      border-radius: 50%;
      display: inline-block;
    }
    .product-badge {
      margin: 2px;
      position: absolute;
      bottom: 0;
      left: 3.1pc;
      height: 15px;
      width: 15px;
      border: 2px solid white;
      border-radius: 50%;
      &.badge-active {
        background-color:#5AFF31;
      };
      &.badge-inactive {
        background-color: #FF3131
      }
    }
    .title {
      font-size: 14px;
      color:#5A5A69
    }
  }