.xc-switch{
    display: flex;
    flex-direction: column;
    &.disabled{
        pointer-events: none;
        cursor: not-allowed;
    }
    & > label{
        height: 18px;
        width: 36px;
        border-radius: 9px;
        cursor: pointer;
        display:block;
        position: relative;
        
        input{
            position: absolute;
            visibility: hidden;
            pointer-events: none;

            &:checked + .box{
                background-color: #5AFF31; 
                border-color: transparent;

            }

            &:checked ~ .lever{
                background-color: white;
                transform: translate(18px,-50%);
            }
        }
        .lever{
            display: block;
            position: absolute;
            top:50%;
            left:2px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            transform: translate(0,-50%);
            background-color: #adb5bd;
            transform: translate(0,-50%);
            transition: transform 0.2s ;
        }
        .box{
            display: block;
            width: 100%;
            height: 100%;
            background-color: white; 
            left: 0;
            top: 0;
            border-radius: 9px;
            position: absolute;
            border: #adb5bd solid 1px;
            transition: background-color 0.1s ;
        }
    }
}