.additionalTableHeader{
  background: #F8F8F8;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;
}

.card{
  border-radius: 15px;
  overflow: hidden;
  min-height: 150px;
  .card-header,.card-body{
    padding: 0;
    background-color: #ffff;
  }
  .card-body{
    padding: 0;
  }
}


.additionalHeader{
  #mainTable{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0px;
    border-radius: 0;
  }
  &.card{
    border-radius: 15px;
    overflow: hidden;
  }
  tbody{
    tr.child-bg{
      background-color: $tableHover;
    }
  }
}

#mainTable{
  border-collapse: collapse;
  overflow: hidden;
  // border-radius: 15px;
  border-top: 1px solid transparent;
  .tableHeader{
    background-color: $tableHeader;  
    th{
      color: $THead;
      font-size: 14px;
    }    
  }
  
  tr{
    td{
      // text-transform: capitalize;
      color: $TParagraph;
      font-size: 0.77rem;
    }
    .actionIcon{
      opacity: 0;
    }
    &:hover{
        background-color: $tableHover;
        .actionIcon{
          opacity: 1;
        }
    }
  }
  .table-row-button {
    width: 30px;
    height: 30px;
    display: flex;
    color: $primary;
    background-color: $XCAccent;
  }
  &.center{
    td,th{
      text-align: center;
      *{
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}
.buttonStyle{
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: #D0EAFF;
  padding-top: 3px;
  border: 0px;
}
.filter-viewType-container {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .record-level-date-range {
    .form-group {
      margin: 0;
      display: flex;
      select {
        padding-right: 30px;
      }
    }
  }
}

.per-page {
  width: 80px;
  display: inline-block;
  margin: 0px 10px;
  padding: 6px 20px 6px 12px;
}

.view-type{
  padding-right: 30px;
}