$filterHeight: 301px;
#filterModal{
  .filterBtn{
    color: $XCParagraph;
    border: 1px dashed $XCParagraph;
    border-radius: 15px;
    background-color: transparent;
    cursor: pointer;
  }
  .chipBg{
    background-color: #EDF7FF;
    border: 1px solid #586FA3;
  }
  .deleteBg{
    background: #586FA3;
  }
}
.card-body{
  .row-eq-height{
    height: $filterHeight;
    padding-right: 15px;
    & > div{
      height: $filterHeight;
      overflow: auto;
    }
    button.filterTypes {
      border: none;
      background-color: inherit;
      color: inherit;
      font-family: inherit;
      text-align: left;
      &:focus {
        outline: none;
      }
      i {
        margin-right: 5px;
      }
    }
  }

  .calendar-container {
    height: 265px;
  }
  .calendar-notice {
    color: $XCSubFont;
    font-weight: 300;
    margin-top: 5px;
    text-shadow: 1px 1px white;
    margin-bottom: 0;
    font-size: 12px;
    padding-left: 10px;
  }
  
}
#searchOption{
  border-right: 1px solid #dee2e6;
  .card-header{
    .input-group{
       display: flex;
       flex-direction: row-reverse;
       border-bottom: 1px solid black;
       .form-control{
         background-color: transparent;
       }
    }
  }
  .card-body{
    // overflow: auto;
    display:block;
    .react-daterange-picker{
      display: block;
    }
    .react-calendar {
      border: none;
    }
    .react-daterange-picker__calendar {
      width: 100% !important;
    }
  }

}
.card-header{
  &.filterSelected{
    height: 56px;
  }
}
#filterSelected{
  height: 56px;
  .p-1{   
    p{
      font-weight: 400;
      margin-bottom: 5px;
    }
    span{
      letter-spacing: 1px;
    }
  }
  .card-body{
    overflow: scroll;
    height: 9pc;
  }
}
.loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.loader:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: $XCSecondary;
  animation: loading 2s linear infinite;
}

@keyframes loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}