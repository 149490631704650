$primaryTextColor: #2B61B4;
$greyTextColor: #676767;

#quotation-detail {
  color: $greyTextColor;
  font-size: 13px;

  .quotationId {
    color: darken($greyTextColor, 10%);
    font-size: 113%;
  }

  .text-primary { color: $primaryTextColor; }

  .beneficiary-header {
    background-color: #ECECEC;
    border-radius: 10px;
    color: $XCHeadFont;
    font-size: 18px;
    font-family: $MediumFontFamily;
  }
}
