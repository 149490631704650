body {
  overflow-x: hidden;
}


.activeNav{
  border-left: 3px solid $XCSecondary !important;
  color: #00DFDE !important;
}

#sidebar-wrapper {
  min-height: 100vh;
  // margin-left: -15rem;
  background: $white;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  img{
    width: 190px;
  }
  .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
  .list-group {
    width: 15rem;
  }
  &.noFlex{
    flex: 0;
    display: flex;
    flex-direction: column;
  }
  .list-group-item{
    border: 0;
    margin: 0px;
  }
  .borderBottom{
    border-bottom: 1px solid black;
    opacity: 0.1;
  }
  div.list-group {
    width: 100%;
  }
}

.container-fluid, .documentation-container, #dashboard{
  animation: fadeIn 350ms ease-in;
}
@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
#page-content-wrapper {
  min-width: 100vw;
  position: relative;
  .container-fluid{
    height: calc(100% - 200px);
  }
  .footer {
    margin: 20px 0 0 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $XCLightGray;
    color: white;
    ul{
      list-style: none;
      padding-left: 20px;
      margin: 5px 0;
      li{
        margin: 0 10px;
      }
    }
    p{
      margin: 5px 20px;
    }
  }
}

.my_50{
  margin: 3rem 0;
  height: calc(100% - 100px);
  align-items: center;
}
.my_30{
  margin: 1rem 0;
  @extend .my_50;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

.navStyle{
  font-size: 14px;
}

.link-style{
  color: #074E8C !important;
  text-decoration: #074E8C underline;
  cursor: pointer;
}

.leadMarketingInfo{
  @media screen and (min-width: 768px) {
    width: 300px;
  }
  margin-right: 5%;
}

.tooltip-container{
  position: relative;
  p{
    position: absolute;
    left: 20px;
    top: -20px;
    padding: 16px;
    margin: 8px;
    box-shadow: 2px 5px 5px #cccccc;
    background-color: #FFFFFF;
    border-radius: 5px;
    z-index: 1000;
    width: 33vw;
  }
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

#menu-title{
  //border-bottom: 1px solid #ebebeb;
  //background-color: $XCWhite;
  margin-left: 0!important;
  margin-right: 0!important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4%;
  // div:first-child{
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  // }
}
