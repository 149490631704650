.initechNode {
  //border: solid 3px #a68200;
  border-bottom: solid 5px $primary;
  border-top: 1px solid $black;
  border-right: 1px solid $black;
  border-left: 1px solid $black;
  border-radius: 5px;
  padding: 15px 5px;
  margin: 0 5px 0 5px;
  min-width: 200px;
  max-width: 300px;
  display: inline-block;
  p{
    margin: 0;
    line-height: 1.5em;
  }
  .org-name{
    font-weight: bold;
    font-size: 0.9em;
  }
  .org-type{
    font-size: 0.8em;
  }
}

#initechOrgChart {
  .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
    border-right: solid 1px #333333;
  }

  .nodeLineBorderTop {
    border-top: solid 1px #333333;
  }
}

.org-icon {
  border: none;
    background: transparent;
    &:focus{
      outline: none;
    }
}