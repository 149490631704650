.react-daterange-picker__wrapper {
  display: none !important;
}


.date-range-wrapper {
  border-bottom: #e9ecef 1px solid;
  z-index: 0;

  .react-daterange-picker {
    display: inline !important;
  }

  .date-range-picker, .date-range-picker:focus {
    border: none;
    background: transparent !important;
  }

  input.date-range-picker {
    width: 210px !important;  
  }

  .date-range-picker:focus {
    box-shadow: none;
  }

  button:focus {
    outline: none;
  }
}
