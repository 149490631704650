.upload-area{
  position: relative;

  .select-file{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    top: 0;
    z-index: 9;
    left: 0;
    display: inline-block;
  }
}

.download-card{
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 1px 1px 4px 0px #ccc;
  line-height: 70px;
  height: 70px;
  border-radius: 8px;
  padding: 0 30px;
  transition: 0.2s;
  overflow: hidden;
  cursor: pointer;

  &:hover{
    box-shadow: 3px 2px 6px 2px #ccc;
  }
  
  .file-type-icon{
    color: #f96161;
    display: inline-block;
    margin-right: 15px;
    font-size: 30px;
    float: left;
    margin-top: 20px;
  }

  i {
    color: #2B61B4;
    font-size: 30px;
    display: inline-block;
    margin-top: 20px;
  }
}