.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: $XCWhite;
    border: none;
    border-bottom: 3px solid $XCPrimary;
    color: $XCPrimary!important;
    font-family: $MediumFontFamily;
  }
  .nav-tabs .nav-link {
    color: $XCParagraph!important;
    border: $Background;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-bottom: 3px solid $XCSecondary;
    cursor: pointer;
    color: $XCPrimary!important;
  }
  .nav-link {
    padding: 0.5rem 1rem;
    margin-right: 1rem;
  }
  .nav-tabs {
    border-bottom: 0!important;
  }