// XpressCover Color Palette
$XCPrimary:     #3E83FC;
$XCSecondary:   #3E83FC;
$XCAccent:      #DCF0FF;
$XCDanger:      #FF6262;
$XCHeadFont:    #5B5B5B;
$XCSubFont:     #626262;
$XCParagraph:   #979797;
$XCLightGray:   #F9F9F9;
$tableTop:      #F8F8F8;
$tableHeader:   #F3F3F3;
$tableHover:    #F4F4F4;
$XCLightBG:     #fcfcfc;
$XCTextField:   #DEE2E4;
$XCWhite:       #FFFFFF;

$disabled: #e9ecef;
$border: #ced4da;
//override B4 color
$primary:       #2B61B4;
$info:          #979797;
$secondary:     #074E8C;

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   $XCHeadFont; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           $XCLightGray;
$cross-line: #ced4da;
//Table Format Color
$THead: #5B5B5B;
$TParagraph: #676767;

// Accordian Table 
$AccordianFont: #393939;

$color-primary: $XCPrimary;

// Primary Button: #Secondary
// Secondary Button Outline: #Secondary
// Less Priority Button: #Paragraph

// Side Menu Background: #FFFFFF
// Side Menu Button: #Paragraph
// Side Menu Button Active: #Primary

// Table Top: #F8F8F8
// Table Header: #F3F3F3
// Table Hover: #F4F4F4

$Background: #FCFCFC;
// Footer: #F9F9F9
$Placeholder: #C4C4C4;
// Input: #HeadFont

// Successful: #Primary

// Poppins Font Size
$LightFontFamily:       "Nunito-Light";
$RegularFontFamily:     "Nunito-Regular";
$MediumFontFamily:      "Nunito-Medium";
$SemiBoldFontFamily:    "Nunito-SemiBold";