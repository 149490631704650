.form-group select.form-control {
    // color: $Placeholder;
    font-size: 14px;
  }
  .form-control::placeholder{
    font-size: 14px;
    color: #495057;
  }

  // Text Field
.form-control, .input-group-text {
    border-color: $XCTextField;
  }

.input-group-text{
    background: transparent;
    border-right: 0;
  }
  .input-group > .form-control {
    border-left: 0;
  }
  .form-control {
    font-size: 14px;
  }