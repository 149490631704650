.btn-primary.customPrimarybtn {
    color: $XCWhite;
    background-color: $XCSecondary;
    font-size: 15px;
    border-radius: 7px;
  }
  .btn-primary {
    color: #fff;
    background-color: $XCSecondary;
    border-color: $XCSecondary;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: $XCSecondary;
    border-color: $XCSecondary;
  }
  .btn-primary:hover, .btn-secondary:hover,.btn-secondary:not(:disabled):not(.disabled):active, 
  .btn-secondary:not(:disabled):not(.disabled).active, 
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: darken($XCSecondary, 5); 
    border-color: darken($XCSecondary, 7);
  }
  .btn-primary:focus, .btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(44,105,157,.5);
  }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: darken($XCSecondary, 5); 
    border-color: darken($XCSecondary, 7);
  }
  .btn-outline-primary {
    color: $XCSecondary;
    border-color: $XCSecondary;
    background-color: transparent;
    &:hover {
      color: #fff;
      background-color: $XCSecondary;
    }
  }
  .btn-info:not(:disabled):not(.disabled):active, 
  .btn-info:not(:disabled):not(.disabled).active, 
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #848484;
    border-color: #7e7e7e;
  }


  .btn-info.customInfobtn {
    color: $XCWhite;
    background-color: $Placeholder;
    border-radius: 7px;
    border: none;
    font-size: 15px;
  }
  .btn-info.customInfobtn:hover, .customInfobtn:focus {
    color: #fff;
    background-color: #848484;
    border-color: #7e7e7e;
  }

  .btn-action {
    background-color: transparent;
    border-radius: 7px;
    border: 1px solid $XCSecondary;
    padding: 0.25rem 1rem;
    font-size: 15px;
    color: $XCSecondary;
    font-family: $MediumFontFamily;
    

    &.btn-primary{
      background-color: #2B61B4 !important;
      color: white !important;
    }
    &:hover {
      background-color: $XCSecondary;
      color: $XCWhite;
      border: 1px solid $XCSecondary;
    }
    &:focus {
      background-color: $XCSecondary;
      color: $XCWhite;
    }
  }