.tagContainer{
  border:1px solid lightgray;
  border-radius: 8px;
  height:auto;
  min-height:100px;
  padding:5px;
  cursor: text;
  display: flex;
  flex-direction: row;
  align-items:flex-start;
  flex-wrap: wrap;
  background-color: white;
  &:focus{
    outline: none;
  }

  .tag-pill{
    background-color: white;
    width: auto;
    min-width: 5rem;
    padding: 1px 8px;
    text-align: center;
    color: black;
    font-weight: 500;
    border-radius: 20px;
    cursor:pointer;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    border: 1.5px solid #e3e3e3;
    font-size: 15px;
  }

  .tag-input-field{
    border:0;
    background-color: transparent;
    flex-grow: 1;
    &:focus{
      outline: none;
    }
  }

  .ReactTags__tagInput{
    flex-grow: 1;
    .ReactTags__tagInputField{
      border:0;
      background-color: transparent;
      flex-grow: 1;
      &:focus{
        outline: none;
      }
    }
  }

  .close{
    font-size: 22px;
    font-weight: bold;
  }

  .red-pill{
    background-color: red;
    color:white;
  }

  .white-pill{
    background-color: white;
    color:black;
  }

  .display-none{
    display: none;
  }
}