body .customModalHeader{
  max-width: 100%;
  width: auto !important;
  min-width: 35pc;
  display: inline-block;
  @at-root .modal {
    display: flex;
}
  .modal-content{
    border-radius: 15px;
    border: 0;
  }
  .modal-header{
    background-color: #586FA3;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  .close{
    opacity: 1;
    color: white;
    text-shadow: none;
    &:hover{
      color: white;
    }
  }
  &.max-width-500{
    max-width: 500px;
  }

}

.commission {
  .percentage-container{
    padding: 0;
    margin: 0;
    input{
      width: 100%;
      height: 35px;
      padding-left: 6px;
      border-radius: 5px 0 0 5px;
      border: 1px solid #ced4da;
      &.wide {
        width: 96px;
      }
    }
  }
  .select-container {
    padding: 0;
    select {
      width: 100%;
      position: relative;
      color: $XCPrimary;
      background: white;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0 5px 5px 0;
      &.blue-back{
        background: $XCAccent;
      }
      &:disabled {
        background-color: $disabled;
        color: inherit;
      }  
    }
    i{
      position: absolute;
      color: $XCPrimary;
      &.disabled {
        color: inherit !important;
      }
    }
  }
  .half-width{
    width: 50%;
  }
  .less-half-width{
    width: 35%;
  }
  .more-half-width{
    width: 65%;
  }
  &.wide{
    width: 600px;
  }
  .whole-width{
    width: 100%;
  }
  .value-type{
    justify-content: center;
    padding: 0;
  }
}



