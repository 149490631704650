.rounded-xl{
    border-radius: 7px !important;
}

.top-right-radius-0 {
    border-top-right-radius: 0 !important;

}
.top-left-radius-0{
    border-top-left-radius: 0 !important;
}  
.border-gray-top{
    border-top: 1px solid rgba(0, 0, 0, 0.125);
} 
.border-gray-left{
    border-left: 1px solid rgba(0, 0, 0, 0.125);
} 
.border-gray-right{
    border-right: 1px solid rgba(0, 0, 0, 0.125);
}