$blue_color: #3E83FC;
$black_color: #5A5A69;
#dashboard {
  padding: 30px 4%;
  // padding: 0 4%;
  #menu-title {
    padding: 0;
  }

  .row {
    margin-bottom: 20px;
  }

  .card {
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 20px;
    font-size: 20px;
    color: $blue_color;
    font-weight: bold;
    font-family: inherit;
    margin-bottom: 10px;
  }

  .cards {
    .card {
      .header {
        word-break: keep-all;
        margin-bottom: 30px;
        color:$black_color;
      }

      .content {
        font-size: 30px;
        position: relative;

        span {
          font-size: 18px;
          font-weight: normal;
          padding-right: 4%;
        }

        .icon-box{
          display: inline-block;
          font-weight: bold;
          position: absolute;
          right: 5px;
          cursor: pointer;
        }

        a {
          font-weight: bold;
          position: absolute;
          right: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .chart-container .card {
    .header {
      color: $black_color;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 3%;
    }

    .chart {
      .recharts-wrapper {
        svg {
          width: 100%;
          font-size: 14px;
          font-weight: lighter;
        }
      }
    }
  }
}

$screen_height_fixed_app_version: "(min-height: 750px)";
@media screen and (min-width: 768px) and #{$screen_height_fixed_app_version} {
  .release_number {
    position: fixed;
    bottom: 2%;
    right: 2%;
  }
}
