.ReactTags__tags{
    flex-grow: 1;
    flex-basis: 0;
    .ReactTags__selected{
      display:flex;
      flex-wrap: wrap;
      .tag-wrapper.ReactTags__tag{
        margin-right:6px;
        margin-bottom: 5px;
        background-color: #EFEFEF;
        padding-left: 4px;
    
        .ReactTags__remove{
          border:none;
        }
      }
      .ReactTags__tagInput{
        width: 220px;
        .ReactTags__tagInputField{
          width: 100%;
        }
      }
    }
  }