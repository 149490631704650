.page-item.active  {
    .page-link{
      font-family: $MediumFontFamily;
      background-color: $Background;
      color: $XCPrimary;
      border: 0;
      border-radius: 0;
    }
  }
  .page-item.disabled {
    .page-link {
      color: #D8D8D8;
      background-color: $Background;
    }
  }
  .page-link {
    border: 0;
    background-color: $Background;
    color: $XCParagraph;
    & :hover {
      border-radius: 0!important;
    }
  }
  .page-item:last-child {
    .page-link {
      border-radius: 0!important;
    }
  }
  .page-item {
    & :hover, :focus {
      background-color: #F4F4F4;
    }
  }