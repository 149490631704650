.xc-button {
  width: 100%;
}

.xc-button-circular {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-top: 3px;
  border: 0px;
}
.normal-gray-text{
  font-size: 14px;
  color: rgb(103, 103, 103);
}

.forbidden {
  .modal-body {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    .xc-button {
      width: 15%;
    }
  }
}