.dateIconStyle{
  border-left: 0;
  border-right: 1px solid #d7dce1 !important;
  border-radius: 0.25rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.dateIconStyleLeft{
  border-right: 0;
  border-left: 1px solid #d7dce1 !important;
  border-radius: 0.25rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.xc-date-picker {
  .input-group{
    flex-wrap: nowrap;
  }
}
.input-group-prepend{
  height: 35px;
}
.push-left {
  margin-left: -36px;
}
.react-datepicker-wrapper{
  width: 100%;
  .react-datepicker__input-container{
    input {
      width: 100%;
      height: 35px;
      border: none;
      padding-left: 12px;
      border-top: 1px solid #d7dce1;
      border-bottom: 1px solid #d7dce1;
      color: #495057;
      font-size: 13px;
      &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
      }
    }
    width: 100%;
    .text-danger-step{
      position: absolute;
      // left: -35px;
    }
    .input-group{
      input{
        border-right: 0;
        border-radius: 0;
      }
    }
  }
}

.react-datepicker{
  .react-datepicker__month-dropdown-container {
    width: 100px;
    select {
      width: 100%;
    }
  }
  .react-datepicker__year-dropdown-container {
    width: 60px;
    select {
      width: 100%;
    }
  }
}

.text-danger-step{
  font-size: 12px;
  color: $XCDanger;
}

.input-group{
  .react-datepicker__input-container{
    .text-danger-step{
      // position: absolute;
      left: -35px;
    }
  }
}

.terminal-icon {
  flex: 1;
}
.terminal-input {
  flex: 11;
}
.custom-disabled {
  background-color: #e9ecef;
  &:focus{
    background-color: #e9ecef;
  }
}
.empty-products-container{
  height: 90vh;
  color: $primary;
}
.flash-message-component{
  animation: fadeIn 500ms ease-in;
}

$border: black;
$default-bg: white;
$header-bg: #ededed;
$category-bg: #dbdbdb;
$hover-bg: #f2fafb;
$header-second-bg: #005192;
$main-field-bg: #c5edff;

.benefit-table-2 {
  margin: 40px 0 20px;
  border: 1px solid #eee;
  width: 100%;
  overflow-x: hidden;

  .title{
    font-family: 'poppins-medium';
    font-size: 16px;
    font-weight: 600;
  }

  p{
    padding: 0 20px
  }

  table{
    font-size: 12px;
    border-top: 1px solid #eee;
    width: 100%;
    border-collapse:collapse;
    overflow: hidden;

    td{
      border-top: 1px solid #eee;
      padding: 5px 10px;
      border-left: 1px solid #eee;
      font-size: 14px;

    }
    tbody{
      overflow: hidden;
    }

    th{
      border-top: 1px solid #eee;
      padding: 5px 10px;
      font-size: 14px;
      text-align: center;
    }

    .blue-header{
      background: #4674C9;
      color: #fff;
    }

    .seprateField{
      background: #c7dbff;
    }

    .fixPrice{
      text-align: center;
    }
  }
}

.benefit-table {
  margin: 2rem 0 1rem;
  table {
    thead {
      tr{
        th{
          padding: 0 0.5rem;
          border: 1px solid $border;
          font-size: 14px;
          text-align: center;
          font-weight: bold;
          background-color: $header-bg;
          &.invisible{
            background-color: $default-bg;
          }
        }
      }

      &.blue-header{
        tr{
          th{
            color: $default-bg;
            background-color: $header-second-bg;
          }
        }
      }
    }
    tbody{
      tr{
        td{
          padding: 0.4rem .5rem;
          border: 1px solid $border;
          font-size: 12px;
          &.category{
            text-align: center;
            font-weight: bold;
            background-color: $category-bg;
          }

          &.price {
            text-align: right;
          }
          &.center {
            text-align: center;
          }
          &.fixPrice {
            text-align: center;
            width: 100px;
          }
          & p{
            &.regular{
              font-weight: normal;
            }
          }
        }
        &:hover{
          background-color: $hover-bg;
        }
        &.empty-row {
          td{
            border: 1px solid $border;
            height: 2rem;
          }
          &:hover{
            background-color: $default-bg;
          }
        }
        &.mainField{
          td {
            background-color: $main-field-bg;
            font-weight: bold;
          }
        }
        &.seprateField{
          td {
            background-color: $category-bg;
            font-weight: bold;
          }
        }
      }
    }
    tfoot{
      tr{
        td{
          padding: 0.4rem 2rem;
          font-size: 12px;
        }
      }
    }
  }
}
@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}