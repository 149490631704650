.modal-content {
  .additional-tag-styles{
    .react-tag-input {
      max-width: 530px;
    } /* Wrapper */
  }
}

.react-tag-input__input {
  background: transparent;
  color: #333;
} /* Inner input */
.react-tag-input__tag {
  background: transparent;
  border: 1px solid $Placeholder;
  border-radius: .5rem;
  padding: .2rem;
} /* Tag wrapper */
.react-tag-input__tag__content {} /* The text content within the tag */
.react-tag-input__tag__remove {
  background: transparent;
} /* The remove button & icon for a tag */