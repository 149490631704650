.font-size-normal{
    font-size: 16px !important;
}

.font-size-small{
    font-size: 14px !important;
}

.font-size-smaller{
    font-size: 12px !important;
}

.font-size-large{
    font-size: 18px !important;
}

.font-size-larger{
    font-size: 20px !important;
}