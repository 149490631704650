.border-orange {
  border: 1px #a68200 solid !important;
}

.border-green {
  border: 1px green solid !important;
}
.border-yellow {
  border: 1px #a68200 solid !important;
}
.border-blue {
  border: 1px #0089cc solid !important;
}


.side-fixed-container{
  @media screen and (min-width: 600px) {
    position: fixed;
    margin-right: 30px;
  }
  background-color: #333;
  color: #cccccc;
  // padding: "15px",
  border-radius: 15px;
  height: 85vh;
   width: 25vw;
  //width: 400px;
  // right: 0
  //.hidden_content{
  //  color: red;
  //  opacity: .5;
  //  height: 0;
  //  overflow: hidden;
  //  position: relative;
  //}
  textarea{
    min-width: 90%;
    font-size: .8em;
    &:focus{
      outline: none;
    }
  }
  button{
    margin: 0 15px 0 0 ;
  }
}
.headers-table{
  font-size: 0.7em;
  td, th{
    color: #cccccc;
    padding: 0;
    margin: 0;
    width: 50%;
    border-bottom: 1px solid #cccccc;
  }
  input{
    background-color: rgba(255, 255, 255, 0.39);
    color: #FFFFFF;
    //background-color: transparent;
    border: none;
    border-radius: 0;
    &:focus{
      //background-color: rgba(255, 255, 255, 0.12);
      //color: #FFFFFF;
    }
  }
}