#userGroups{
  #accordion{
    .accordion{
      border: 1px solid rgba(0, 0, 0, 0.125);
      .collapse{
        .card-body{
          padding: 0;
        }
      }
    }
  }
}