@font-face {
  font-family: 'xpress-cover-font';
  src:  url('fonts/xpress-cover-font.eot?22m80b');
  src:  url('fonts/xpress-cover-font.eot?22m80b#iefix') format('embedded-opentype'),
  url('fonts/xpress-cover-font.ttf?22m80b') format('truetype'),
  url('fonts/xpress-cover-font.woff?22m80b') format('woff'),
  url('fonts/xpress-cover-font.svg?22m80b#xpress-cover-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'xpress-cover-font' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-motorcar:before {
  content: "\e935";
}

.icon-flexi-pa:before {
  content: "\e932";
}
.icon-hospital:before {
  content: "\e92d";
}
.icon-wallet:before {
  content: "\e92e";
}
.icon-planeProduct1:before {
  content: "\e931";
}
.icon-plan:before {
  content: "\e92c";
}
.icon-dooKey:before {
  content: "\e92a";
}
.icon-padLock:before {
  content: "\e92b";
}
.icon-teamDirectory:before {
  content: "\e926";
}
.icon-search:before {
  content: "\e928";
}
.icon-planeProduct:before {
  content: "\e927";
}
.icon-add:before {
  content: "\e900";
}
.icon-leftArrow:before {
  content: "\e901";
}
.icon-downArrow:before {
  content: "\e902";
}
.icon-up-arrow:before {
  content: "\e933";
}
.icon-rightArrow:before {
  content: "\e903";
}
.icon-error:before {
  content: "\e905";
}
.icon-errorOutline:before {
  content: "\e906";
}
.icon-checkedOutline:before {
  content: "\e908";
}
.icon-checked:before {
  content: "\e90a";
}
.icon-aeroplane:before {
  content: "\e90b";
}
.icon-arrival:before {
  content: "\e90c";
}
.icon-depart:before {
  content: "\e90d";
}
.icon-building:before {
  content: "\e90e";
}
.icon-copy:before {
  content: "\e90f";
}
.icon-event:before {
  content: "\e910";
}
.icon-calendar:before {
  content: "\e911";
}
.icon-file:before {
  content: "\e912";
}
.icon-fileEdit:before {
  content: "\e913";
}
.icon-fileSetting:before {
  content: "\e925";
}
.icon-garbage:before {
  content: "\e914";
}
.icon-handMoney:before {
  content: "\e915";
}
.icon-hyperlink:before {
  content: "\e916";
}
.icon-stats:before {
  content: "\e917";
}
.icon-list:before {
  content: "\e918";
}
.icon-log:before {
  content: "\e919";
}
.icon-pdf:before {
  content: "\e91a";
}
.icon-pencil:before {
  content: "\e91b";
}
.icon-placeholder:before {
  content: "\e91c";
}
.icon-product:before {
  content: "\e91d";
}
.icon-puzzle:before {
  content: "\e91e";
}
.icon-rotateArrow:before {
  content: "\e91f";
}
.icon-stopwatch:before {
  content: "\e920";
}
.icon-user:before {
  content: "\e921";
}
.icon-userSetting:before {
  content: "\e922";
}
.icon-multipleUser:before {
  content: "\e923";
}
.icon-settings:before {
  content: "\e924";
}
.icon-home:before {
  content: "\e934";
}
.icon-brokenLink:before {
  content: "\e929";
}
.icon-home-insurance:before {
  content: "\e904";
}
.icon-medical-insurance:before {
  content: "\e907";
}
.icon-motor-insurance:before {
  content: "\e909";
}
.icon-warning:before {
  content: "\e930";
}
.icon-notice:before {
  content: "\e930";
}
.icon-note:before {
  content: "\e930";
}
.icon-alert-circle:before {
  content: "\e92f";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-up-arrow-line:before {
  content: "\ea3a";
}
.icon-down-arrow-line:before {
  content: "\ea3e";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-travel-insurance:before {
  content: "\e931";
}