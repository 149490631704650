.accordianfontSize {
    #accordion {
      .btn-link {
        font-size: 16px;
        text-decoration: none;
      }
    }
  }
  
  #accordion {
    border-radius: 10px;
    .btn-link {
      color: $AccordianFont;
      font-size: 14px;
      text-decoration: none;
    }
    .accordion{
        box-shadow: none !important;
        padding-bottom: 1px;
        border: none;
        .card-header{
          border-bottom: none;
          background-color: #f8f8f8;
          cursor: pointer;
        }
      }
  }