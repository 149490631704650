.xc-custom-checkbox{
    display: flex;
    flex-direction: column;
    &.disabled{
        pointer-events: none;
        cursor: not-allowed;
    }
    & > label{
        height: 20px;
        width: 20px;
        border-radius: 2px;
        cursor: pointer;
        display:block;
        position: relative;
        margin:0;
        
        input{
            position: absolute;
            visibility: hidden;
            pointer-events: none;

            &:checked + .box{
                background-color: #5AFF31; 
                border-color: #5AFF31;

            }

            &:checked ~ .lever{
                // background-color: white;
            transform: rotateZ(37deg);
            opacity:1;
            }
        }
        .lever{
            position: absolute;
            top: 2px;
            left: 1px;
            width: 8px;
            height: 13px;
            border-top: 2px solid transparent;
            border-left: 2px solid transparent;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            opacity:0;
            transform: rotateZ(0deg);
            transform-origin: 100% 100%;
            transition: transform 0.2s, opacity 0.2s;
        }
        .box{
            display: block;
            width: 100%;
            height: 100%;
            background-color: white; 
            left: 0;
            top: 0;
            border-radius: 2px;
            position: absolute;
            border: #828588 solid 2px;
            transition: background-color 0.1s ;
        }
    }
}