
@font-face {
  font-family: 'Nunito-Regular';
  font-weight: 400;
  src: url('Nunito/Nunito-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito-Light';
  font-weight: 300;
  src: url('Nunito/Nunito-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito-Medium';
  font-weight: 500;
  src: url('Nunito/Nunito-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito-SemiBold';
  font-weight: 600;
  src: url('Nunito/Nunito-SemiBold.ttf') format('truetype');
}